<template>

  <div v-if="data.edit">
    <form @submit.prevent="submit">

      <div class="input-group input-group-sm mb-1">
        <button class="btn" @click="data.edit = !data.edit" :disabled="data.permission.id === undefined">
          <i class="fa fa-pen"/>
        </button>
        <input type="text" class="form-control"  v-model="data.newName">
        <button :class="{'is-loading': data.loadingSave}" class="btn btn-success" @click.prevent="submit">
          <i class="fa fa-save"/>
        </button>
        <button :class="{'is-loading': data.loadingDelete}" class="btn btn-danger" @click.prevent="remove">
          <i class="fa fa-trash"/>
        </button>
      </div>

    </form>
  </div>

  <div v-else>
    <div class="input-group input-group-sm mb-1">
      <button class="btn" @click="data.edit = !data.edit">
        <i class="fa fa-pen"/>
      </button>
      <code>{{ props.permission.name }}</code>
    </div>
  </div>

</template>

<script setup>
import {defineProps, onMounted, reactive} from "vue";
import {ajax} from "slab-vue-utils";

// TODO: Add autocomplete on the name, based on common other permissions.

const props = defineProps([
    "permission",
    "userid",
    "remove",
])

const data = reactive({
  permission: {},
  newName: "",
  edit: false,
  loadingDelete: false,
  loadingSave: false,
})

function submit() {
  let postData = data.permission
  postData.name = data.newName
  let respFn = (resp) => {
    data.permission = resp.data.data
    data.edit = false
  }
  data.loadingSave = true
  if (data.permission.id === undefined) {
    ajax.authPost("/id/v1/users/" + props.userid + "/permissions", postData).then(respFn)
    data.loadingSave = false
  } else {
    ajax.authPut("/id/v1/users/" + props.userid + "/permissions/" + props.permission.id, postData).then(respFn)
    data.loadingSave = false
  }
}

function remove() {
  if (data.permission.id !== undefined) {
    data.loadingDelete = true
    ajax.authDelete("/id/v1/users/" + props.userid + "/permissions/" + props.permission.id).then(resp => {
      props.remove(data.permission)
    })
  } else {
    props.remove(data.permission)
  }
}

onMounted(() => {
  data.permission = props.permission
  data.newName = data.permission.name
  data.edit = data.permission.id === undefined
})
</script>monospace

<style>
.input {
  font-family: monospace;
}
</style>

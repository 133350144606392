<template>

  <h1>
    {{ t("client", 2) }}
  </h1>

  <table :class="{'loading': data.list.loading}" class="table table-striped table-hover">
    <thead>
    <tr>
      <th style="width: 70px;"></th>
      <th><Sort :list-data="data.list" column="name">{{ t("name")}}</Sort></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="client in data.list.data">
      <td><router-link :to="{name: 'client-info', params: {id: client.id}}" class="btn btn-sm btn-info">{{ t("info") }}</router-link></td>
      <td>{{ client.name }}</td>
    </tr>
    </tbody>
  </table>

  <Pagination :list-data="data.list"/>

</template>

<script setup>
import {useI18n} from "vue-i18n"
import {useRoute, useRouter} from "vue-router";
import {onMounted, reactive} from "vue";
import {list} from "slab-vue-utils";
import Sort from "slab-vue-utils/Components/List/Sort"
import Pagination from "slab-vue-utils/Components/List/Pagination.vue";

const {t} = useI18n()
const route = useRoute()
const router = useRouter()
const data = reactive({
  list: list.createData(router, "client-list", "/api/curta/clients")
})

onMounted(() => {
  data.list.loadFromQuery(route.query)
})
</script>

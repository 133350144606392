<template>

  <h1>
    {{ t("merchant", 2) }}
  </h1>

  <table :class="{'loading': data.list.loading}" class="table table-striped table-hover">
    <thead>
    <tr>
      <th style="width: 70px;"></th>
      <th><Sort :list-data="data.list" column="name">{{ t("name") }}</Sort></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="merchant in data.list.data">
      <td><router-link :to="{name: 'merchant-info', params: {id: merchant.id}}" class="btn btn-sm btn-info">{{ t("info")}}</router-link></td>
      <td>{{ merchant.name}}</td>
    </tr>
    </tbody>
  </table>

  <Pagination :list-data="data.list"/>

</template>

<script setup>
import {useI18n} from "vue-i18n";
import Sort from "slab-vue-utils/Components/List/Sort"
import Pagination from "slab-vue-utils/Components/List/Pagination";
import {useRoute, useRouter} from "vue-router";
import {onMounted, reactive} from "vue";
import {list} from "slab-vue-utils";

const {t} = useI18n()
const route = useRoute()
const router = useRouter()
const data = reactive({
  list: list.createData(router, "merchant-list", "/api/curta/merchants")
})

onMounted(() => {
  data.list.loadFromQuery(route.query)
})
</script>

<template>
  <router-view></router-view>
</template>

<script setup>
import {onMounted} from "vue"

onMounted(() => {
  document.querySelector("#init-loader").remove()
})
</script>

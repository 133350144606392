<template>

  <div class="float-end">
    <!-- TODO: functions. -->
  </div>

  <h1>
    {{ t("user", 2) }}
  </h1>

  <div class="card mt-3">
    <div class="card-body">

      <FilterTrigger :list-data="data.list"/>

      <Filter :list-data="data.list">
        <div class="row">
          <div class="col-md-4">
            <label class="form-label">{{ t("email") }}</label>
            <input type="text" class="form-control" name="name" v-model="data.list.filters['email']">
          </div>
        </div>
      </Filter>
    </div>
  </div>

  <table :class="{'loading': data.list.loading}" class="table table-striped table-hover">
    <thead>
    <tr>
      <th style="width: 70px;"></th>
      <th><Sort :list-data="data.list" column="email"/>{{ t("email") }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="user in data.list.data">
      <td><router-link :to="{name: 'user-info', params: {id: user.id}}" class="btn btn-sm btn-info">Info</router-link></td>
      <td>{{ user.email}}</td>
    </tr>
    </tbody>
  </table>

  <Pagination :list-data="data.list"></Pagination>

</template>

<script setup>
import {onMounted, reactive} from "vue";
import {useRouter, useRoute} from "vue-router"
import {useI18n} from "vue-i18n"
import {list} from "slab-vue-utils"
import Sort from "slab-vue-utils/Components/List/Sort"
import Filter from "slab-vue-utils/Components/List/Filter"
import Pagination from "slab-vue-utils/Components/List/Pagination"
import FilterTrigger from "slab-vue-utils/Components/List/FilterTrigger"

const {t} = useI18n()
const route = useRoute()
const router = useRouter()
const data = reactive({
  list: list.createData(router, "user-list", "/api/id/v1/users"),
})

onMounted(() => {
  data.list.loadFromQuery(route.query)
})
</script>

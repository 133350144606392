<template>
  <h2 class="title is-2">Id</h2>
  <div class="row">

    <div class="col-md-3">
      <div class="card">
        <div class="card-body">
          <h5>{{ t("user", 2)}}</h5>
          <div class="number">
            {{ data.numberOfUsers }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3">
      <div class="card">
        <div class="card-body">
          <h5>{{ t("logged_in")}}</h5>
          <div class="number">
            {{ data.loggedInUsers }}
          </div>
        </div>
      </div>
    </div>

  </div>

  <h2 class="title is-2">Curta</h2>
  <div class="row">

    <div class="col-md-3">
      <div class="card">
        <div class="card-body">
          <h5>{{ t("something") }}</h5>
          <div class="number">
            123 456 789
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, reactive} from "vue"
import {ajax} from "slab-vue-utils"
import {useI18n} from "vue-i18n";

const {t} = useI18n()
const data = reactive({
  numberOfUsers: 0,
  loggedInUsers: 0,
})

onMounted(() => {
  ajax.authGet("/api/id/v1/abacus-home").then(resp => {
    data.numberOfUsers = resp.data.numberOfUsers
    data.loggedInUsers = resp.data.loggedInUsers
  })
})
</script>

<template>
  <div v-for="d in data.data">
    <h1 style="padding-top: 500px;">{{ d.name }}</h1>
    <div v-for="bg in d.bg" :style="'display: inline-block; padding: 5%; width: ' + (99.9 / d.bg.length) +'%; background-color: '+ bg">
      <div v-for="c in d.color" :style="'padding: 30px; margin: 30px; color: '+ getFontColor(c)+'; background-color: ' + c">
        This is some text.
      </div>
    </div>
  </div>
</template>

<script setup>
import {reactive} from "vue";

const data = reactive({
  data: [
    {
      name: "folq",
      bg: ["#eaedf8", "#1a1919"],
      color: [
        "#19564a", "#337266", "#4d988a",
        "#ae8300", "#f7cfc7", "#fae1dc",
        "#291b95", "#1f7cbb", "#429cda",
        "#b93116",
      ]
    },
    {
      name: "lyyti",
      bg: ["#fff", "#000"],
      color: [
        "#11302e", "#045b56", "#05756f","#42ced7",
        "#ff0000", "#FF6347", "#ef6872"
      ]
    },
    {
      name: "goodfeed",
      bg: ["#fff", "#fff6f0", "#ecfdf3", "#000"],
      color: [
        "#02363d", "#17494e", "#027a48", "#00b380",
        "#b42318", "#fa733d", "#ffaf38",
        "#6363f9",
        "#fef3f2", "#f9ede5", "#fff6f0",
        "#dbebe6", "#ecfdf3"
      ]
    },
    {
      name: "devrant",
      bg: ["#fff", "#000"],
      color: [

        "#2A8B9D", "#7BC8A4",
        "#e62117", "#D55161", "#e9834b", "#f99a66",
        "#5C38A6", "#40415A", "#54556E", "#A973A2",
        "#3b5998", "#2A8B9D", "#1da1f2",
        "#aaaab8", "#d8d8d8",
      ],
    },
    {
      name: "njalla",
      bg: ["#fff", "#000"],
      color: [
        "#1F353F", "#4bb390", "#40E0D0", "#31708f",
        "#707070", "#7C898E", "#A8B1B5", "#BCC3C6",
        "#D7D7D7", "#e5e5e5", "#e5e8e9",
        "#E8EBEC", "#f1f0f0", "#f5f6f6",
        "#ebccd1", "#f2dede", "#faebcc", "#fcf8e3",
        "#bce8f1", "#dffcf6", "#d9edf7",
        "#FF000038", "#a94442", "#b34b4b",
        "#f20000", "#F72A3B",
        "#18C499", "#42F2A1", "#D8F05E", "#F4F6AD",
        "#8a6d3b", "#ce985e", "#FFDD00",
        "#3E33FF",
        "#473C78", "#781848", "#F2BBE9",
      ]
    },
    {
      name: "noom",
      bg: ["#fff", "#f6f4ee", "#191717"],
      color: [
          "#2b4010", "#316700", "#a9d15a", "#c8e3b1",
          "#1d3a44", "#05727a", "#6cc1b6", "#b4e0d8",
          "#5f110e", "#aa3521", "#fb513b", "#f4beae",
          "#561d48", "#a82f67", "#f2aacd", "#ebccd9",
          "#ad925e", "#d3c9b4", "#e4dece", "#f6f4ee",
          "#191717", "#ffffff"
      ]
    },
    {
      name: "betterhelp",
      bg: ["#eee", "#111"],
      color: [
        "#325343", "#397A4A", "#457777",
        "#75C163", "#9BD58B", "#A6DE9B",
        "#A75D00", "#F8E16C", "#A0CFCE",
      ]
    },
    {
      name: "grain and mortar",
      bg: ["#fff", "#f7f5ee", "#c1c0cb", "#030303"],
      color: [
        "#818e6a", "#aeb99e",
        "#ff8652", "#fd976a",
        "#c1c0cb", "#f7f5ee",
        "#030303",
      ],
    },
    {
      name: "Blue with Yellow accent",
      bg: ["#dfdfdf", "#f8f8f8", "#11122f", "#0a0a2b"],
      color: [
        "#000073", "#001EC5", "#4D4DFF","#9A9AFF",
        "#E6E6FF", "#FFB300"
      ],
    },
    {
      name: "Faded Blue with red accent",
      bg: ["#dddddd", "#f8f8f8", "#191f25", "#0c0d11"],
      color: [
        "#3d5a80", "#98c1d9", "#e0fbfc",
        "#ee6c4d", "#293241",
      ],
    },
    {
      name: "Green to yellow",
      bg: ["#dddddd", "#f8f8f8", "#1b1b1b", "#1f1f2b"],
      color: [
        "#033f63", "#28666e", "#7c9885",
        "#b5b682", "#fedc97"
      ],

    },
    {
      name: "mint",
      bg: ["#fff", "#d9d9d9", "#353535", "#000"],
      color: [
        "#284b63", "#3c6e71",
        "#ffffff", "#d9d9d9", "#353535",
      ],
    },
    {
      name: "5 diff",
      bg: ["#fff", "#323031", "#000"],
      color: [
        "#177e89", "#084c61", "#db3a34",
        "#ffc857", "#323031"
      ],
    },
    {
      name: "green brown",
      bg: ["#ddd", "#222"],
      color: [
        "#3C887E",  "#5B7B7A", "#E0F2E9",
        "#A17C6B", "#CEB5A7"
      ]
    },
    {
      name: "own",
      bg: ["#fff", "#ddd", "#222"],
      color: ["#338877", "#ffaf87", "#333333", "#a36aff"],
    }
  ],
})

function getFontColor(backgroundColor) {
  // Helper function to convert hex color to RGB
  function hexToRgb(hex) {
    // Remove the hash if it's there
    hex = hex.replace(/^#/, '');

    // Convert 3-digit hex to 6-digit hex
    if (hex.length === 3) {
      hex = hex.split('').map((char) => char + char).join('');
    }

    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return [r, g, b];
  }

  // Calculate the lightness of the color
  function calculateLightness([r, g, b]) {
    return (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
  }

  const rgb = hexToRgb(backgroundColor);
  const lightness = calculateLightness(rgb);

  // Return black for light backgrounds and white for dark backgrounds
  return lightness > 0.5 ? '#000000' : '#ffffff';
}
</script>
<template>

  <h1>
    {{ t("occasion", 2) }}
  </h1>

  <div class="card mt-3">
    <div class="card-body">

      <FilterTrigger :list-data="data.list"/>

      <Filter :list-data="data.list">
        Some filters goes here.
      </Filter>

      <table :class="{'loading': data.list.loading}" class="table table-striped table-hover">
        <thead>
        <tr>
          <th style="width: 70px;"></th>
          <th><Sort :list-data="data.list" column="name">{{ t("name") }}</Sort></th>
          <th><Sort :list-data="data.list" column="type">{{ t("type") }}</Sort></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="occasion in data.list.data">
          <td><router-link :to="{name: 'occasion-info', params: {id: occasion.id}}" class="btn btn-sm btn-info">{{ t("info") }}</router-link></td>
          <td>{{ occasion.name }}</td>
          <td>{{ occasion.type}}</td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>

</template>

<script setup>
import {onMounted, reactive} from "vue"
import {useRoute, useRouter} from "vue-router"
import {useI18n} from "vue-i18n"
import {list} from "slab-vue-utils"
import FilterTrigger from "slab-vue-utils/Components/List/FilterTrigger"
import Filter from "slab-vue-utils/Components/List/Filter"
import Sort from "slab-vue-utils/Components/List/Sort"

const {t} = useI18n()
const route = useRoute()
const router = useRouter()
const data = reactive({
  list: list.createData(router, "occasion-list", '/api/jirugi-base/occasions')
})

onMounted(() => {
  data.list.loadFromQuery(route.query)
})
</script>
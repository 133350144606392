<template>
  <h2 class="title id-2">Id</h2>

  <pre>{{ data.key}}</pre>
</template>

<script setup>

import {onMounted, reactive} from "vue";
import {ajax} from "slab-vue-utils";

const data = reactive({
  key: "",
})

onMounted(
    ajax.authGet("/id/public-key", resp => {
      data.key = resp.data.key
  })
)
</script>

<template>

  <h1>
    {{ t("row", 1) }}
  </h1>

  <div class="row">
    <div class="col-md-6">
      <div class="card mt-3">
        <div class="card-body">
          <form @submit.prevent="submit">

            <h5>{{ t("general") }}</h5>

            <div class="mb-3">
              <label class="form-label">{{ t("id") }}</label>
              <input class="form-control" type="text" name="id" v-model="data.row.id" disabled="disabled">
            </div>

            <div class="mb-3">
              <label class="form-label">{{ t("title") }}</label>
              <input class="form-control" type="text" name="title" v-model="data.row.title">
            </div>

            <div class="mb-3">
              <label class="form-label">{{ t("timestamp") }}</label>
              <input class="form-control" type="text" name="datetime" v-model="data.row.dateTime">
            </div>

            <hr>

            <h5>
              Amount
              <button class="btn btn-sm btn-info" @click="data.simpleAmount = !data.simpleAmount">{{ data.simpleAmount ? t("more") : t("less") }}</button>
            </h5>

            <div v-if="data.simpleAmount">
              {{ data.row.amountInclVat / Math.pow(10, data.row.valuePrecision) }} {{ data.row.currency }} ({{ data.row.quantity }} pc)
            </div>

            <div v-else>
              <div  class="mb-3">
                <label class="form-label">{{ t("amountInclVat") }}</label>
                <input class="form-control" type="number" name="amountInclVat" v-model="data.row.amountInclVat">
              </div>

              <div class="mb-3">
                <label class="form-label">{{ t("amountExclVat") }}</label>
                <input class="form-control" type="number" name="amountExclVat" v-model="data.row.amountExclVat">
              </div>

              <div class="mb-3">
                <label class="form-label">{{ t("amountVat") }}</label>
                <input class="form-control" type="number" name="amountVat" v-model="data.row.amountVat">
              </div>

              <div class="md-3">
                <label class="form-label">{{ t("amountNotApplied") }}</label>
                <input class="form-control" type="number" name="amountNotApplied" v-model="data.row.amountNotApplied">
              </div>

              <div class="mb-3">
                <label class="form-label">{{ t("valuePrecision")}}</label>
                <input class="form-control" type="number" name="valuePrecision">
              </div>

              <div class="mb-3">
                <label class="form-label">{{ t("currency")}}</label>
                <input class="form-control" type="text" name="currency" v-model="data.row.currency">
              </div>
            </div>

            <div class="btn-group mb-3">
              <button class="btn btn-success" :class="{'is-loading': data.loadingSave}">{{ t("save") }}</button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import {onMounted, reactive} from "vue";
import {ajax} from "slab-vue-utils";
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router";

const {t} = useI18n()
const route = useRoute()
const data = reactive({
  simpleAmount: true,
  row: {},
})

function submit() {
  console.log("save stuff")
}

onMounted(() => {
  ajax.authGet("/api/curta/rows/" + route.params.id).then(resp => {
    data.row = resp.data.data
  })
})
</script>

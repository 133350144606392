<template>

  <h1>
    {{ t("invoice", 2) }}
  </h1>

  <table :class="{'loading': data.list.loading}" class="table table-striped table-hover">
    <thead>
    <tr>
      <th style="width: 70px;"></th>
      <th>Tot</th>
      <th>Not applied</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="invoice in data.list.data">
      <td><router-link :to="{name: 'invoice-list', param: {id: invoice.id}}" class="btn btn-sm btn-info">{{ t("info") }}</router-link></td>
      <td>{{ invoice.amountInclVat / Math.pow(10, invoice.valuePrecision) }}</td>
      <td>{{ invoice.amountNotApplied / Math.pow(10, invoice.valuePrecision) }}</td>
    </tr>
    </tbody>
  </table>

  <Pagination :list-data="data.list"></Pagination>

</template>

<script setup>
import {useI18n} from "vue-i18n";
import {useRoute, useRouter} from "vue-router";
import {onMounted, reactive} from "vue";
import {list} from "slab-vue-utils";
import Pagination from "slab-vue-utils/Components/List/Pagination.vue";

const {t} = useI18n()
const route = useRoute()
const router = useRouter()
const data = reactive({
  list: list.createData(router, "invoice-list", "/api/curta/invoices")
})

onMounted(() => {
  data.list.loadFromQuery(route.query)
})
</script>

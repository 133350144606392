<template>

  <h1>
    {{ t("transaction", 2) }}
  </h1>

  <table :class="{'loading': data.list.loading}" class="table table-striped table-hover">
    <thead>
    <tr>
      <th style="width: 70px;"></th>
      <th>Tot</th>
      <th>not applied</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="transaction in data.list.data">
      <td><router-link :to="{name: 'transaction-info', params: {id: transaction.id}}" class="btn btn-sm btn-info">{{ t("info") }}</router-link></td>
      <td>{{ transaction.amount }}</td>
      <td>{{ transaction.amountNotApplied }}</td>
    </tr>
    </tbody>
  </table>

  <Pagination :list-data="data.list"></Pagination>

</template>

<script setup>
import {onMounted, reactive} from "vue"
import {useRoute, useRouter} from "vue-router"
import {useI18n} from "vue-i18n"
import {list} from "slab-vue-utils"
import Pagination from "slab-vue-utils/Components/List/Pagination.vue";

const {t} = useI18n()
const route = useRoute()
const router = useRouter()
const data = reactive({
  list: list.createData(router, "transaction-list", "/api/curta/transactions")
})

onMounted(() => {
  data.list.loadFromQuery(route.query)
})
</script>

<template>
  <div class="login-content">

    <h2>Abacus</h2>

    <div v-if="data.errorMessage !== ''" class="alert alert-danger">
      {{ data.errorMessage }}
    </div>

    <form @submit.prevent="login">

      <input type="text" v-model="data.email" class="form-control mb-1" :placeholder="t('email')">
      <input type="password" v-model="data.password" class="form-control mb-5" :placeholder="t('password')">

      <div class="d-grid gap-2">
        <button type="submit" :class="{'is-loading': data.loading}" class="btn btn-primary btn-lg">
          {{ t("login") }}
        </button>
      </div>

    </form>

  </div>
</template>

<script setup>
import {reactive} from "vue";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import axios from "axios";
import {id, ajax} from "slab-vue-utils";

const {t} = useI18n()
const router = useRouter()
const data = reactive({
  loading: false,
  errorMessage: "",
  email: "",
  password: "",
})

function login() {
  if (data.email === "") {
    return
  }

  data.loading = true
  id.login(data.email, data.password).then(resp => {
    if (!id.tokenHasPermission(resp.data.access, "abacus.master")) {
      data.errorMessage = t("missing_permission")
      router.push({name: "login"})
      return
    }
    router.push({name: "home"})
  }).catch(error => {
    if (error.response.status === 401) {
      data.errorMessage = t("wrong_credentials")
    } else {
      data.errorMessage = t("login_error")
    }
  }).finally(() => {
    data.loading = false
  })
}
</script>

<style lang="scss" scoped>
.login-content {
  text-align: center;
  width: 300px;
  left: 50%;
  top: 40%;
  position: fixed;
  transform: translate(-50%, -50%);

  #logo {
    width: 80px;
    margin-bottom: 40px;
  }
}

.login-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
</style>

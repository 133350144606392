import { createApp } from "vue"
import App from "./App"
import router from "./router";
import vueI18n from "./i18n"
import {id} from "slab-vue-utils";

import "@fortawesome/fontawesome-free/js/all"
import "@fortawesome/fontawesome-free/css/all.css"
import "./style.scss"

const app = createApp(App)
app.use(router)
app.use(vueI18n)

id.checkAuth().then(resp => {
    // Store some permissions?
    // TODO: do more permission search.
    app.mount("#app")
}).catch(() => {
    app.mount("#app")
    router.replace({name: "login"})
})

<template>

  <h1>
    {{ t("row", 2) }}
  </h1>

  <table :class="{'loading': data.list.loading}" class="table table-striped table-hover">
    <thead>
    <tr>
      <th style="width: 70px;"></th>
      <th><Sort :list-data="data.list" column="title">{{ t("title") }}</Sort></th>
      <th><Sort :list-data="data.list" column="amountInclVat">{{ t("amountInclVat") }}</Sort></th>
      <th><Sort :list-data="data.list" column="amountNotApplied">{{ t("amountNotApplied") }}</Sort></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="row in data.list.data">
      <td><router-link :to="{name: 'row-info', params: {id: row.id}}" class="btn btn-sm btn-info">{{ t("info") }}</router-link></td>
      <td>{{ row.title }}</td>
      <td>{{ row.amountInclVat / Math.pow(10, row.valuePrecision) }}</td>
      <td>{{ row.amountNotApplied / Math.pow(10, row.valuePrecision )}}</td>
      <!-- show if invoiced -->
    </tr>
    </tbody>
  </table>

  <Pagination :list-data="data.list"></Pagination>

</template>

<script setup>

import {useI18n} from "vue-i18n";
import {onMounted, reactive} from "vue";
import {useRoute, useRouter} from "vue-router";
import {list} from "slab-vue-utils";
import Pagination from "slab-vue-utils/Components/List/Pagination.vue";
import Sort from "slab-vue-utils/Components/List/Sort";

const {t} = useI18n()
const route = useRoute()
const router = useRouter()
const data = reactive({
  list: list.createData(router, "row-list", "/api/curta/rows")
})

onMounted(() => {
  data.list.loadFromQuery(route.query)
})
</script>

<template>

  <h2>Curta</h2>

  <p>
    <button @click.prevent="invoicing" class="btn btn-info">create invoices</button>
  </p>

  <p>
    check payments with the bank-integration
  </p>

  <p>
    Dashboard for the pipeline!
  </p>

</template>

<script setup>

import {ajax} from "slab-vue-utils";

function invoicing() {
  ajax.authPost("/api/curta/invoicing", {}).then(resp => {
    console.log("resp", resp)
  }).catch(err => {
    console.log("err", err)
  })
}
</script>

import {createI18n} from "vue-i18n";

export default createI18n({
    legacy: false,
    locale: "en",
    messages: {
        sv: {
            client: "Klient|Klienter",
            customer: "Kund|Kunder",
            merchant: "Handlare|Handlare",
            row: "Rad|Rader",
            invoice: "Faktura|Fakturor",
            transaction: "Transaktion|Transaktioner",

            user: "Användare|Användare",
            permission: "Rättighet|Rättigheter",
            home: "Hem",
            login: "Logga in",
            logout: "Logga ut",
            save: "Spara",
            add: "Lägg till",
            email: "Epost",
            password: "Lösenord",
            general: "Generellt",
            id: "ID",
            title: "Titel",
            timestamp: "Tidpunkt",
            amountInclVat: "Belopp (ink. moms)",
            missing_permission: "Användaren saknar som rätta rättigheterna.",
            wrong_credentials: "Felaktigt epost eller lösenord!",
            login_error: "Något gick snett under inloggningen, testa igen senare.",
        },
        en: {
            client: "Client|Clients",
            customer: "Customer|Customers",
            merchant: "Merchant|Merchants",
            row: "Row|Rows",
            invoice: "Invoice|Invoices",
            transaction: "Transaction|Transactions",

            user: "User|Users",
            permission: "Permission|Permissions",
            home: "Home",
            login: "Login",
            logout: "Logout",
            save: "Save",
            add: "Add",
            email: "Email",
            password: "Password",
            general: "General",
            id: "ID",
            title: "Title",
            timestamp: "Timestamp",
            amountInclVat: "Amount (inc. vat)",
            missing_permission: "The user is missing the correct credentials.",
            wrong_credentials: "Wrong credentials",
            login_error: "Something went wrong during login. Try again later.",
        },
    }
})

<template>

  <h1>
    {{ t("merchant", 1) }}
  </h1>

  <div class="row">
    <div class="col-md-6">
      <div class="card -mt-3">
        <div class="card-body">
          <form @submit.prevent="submit">

            <div class="mb-3">
              <label class="form-label">{{ t("id") }}</label>
              <input class="form-control" type="text" name="id" v-model="data.merchant.id" disabled="disabled">
            </div>

            <div class="mb-3">
              <label class="form-label">{{ t("name") }}</label>
              <input class="form-control" type="text" name="name" v-model="data.merchant.name">
            </div>

            <div class="btn-group mb-3">
              <button class="btn btn-success" :class="{'is-loading': data.loadingSave}">{{ t("save") }}</button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router";
import {onMounted, reactive} from "vue";
import {ajax} from "slab-vue-utils";

const {t} = useI18n()
const route = useRoute()
const data = reactive({
  loadingSave: true,
  merchant: {},
})

function submit() {
  data.loadingSave = true
  ajax.authPut("/api/curta/merchants/" + route.params.id, data.merchant).then(resp => {
    data.merchant = resp.data.data
    data.loadingSave = false
  })
}

onMounted(() => {
  ajax.authGet("/api/curta/merchants/" + route.params.id, data.merchant).then(resp => {
    data.merchant = resp.data.data
    data.loadingSave = false
  })
})
</script>

import {createRouter, createWebHistory} from "vue-router"

import Login from "./Views/Login"
import Home from "./Views/Home"
import Colors from "./Views/Colors"
import Public from "./Views/Public"

// Id
import IdHome from "./Views/Id/Home"
import UserList from "./Views/Id/UserList"
import UserInfo from "./Views/Id/UserInfo"

import CurtaHome from "./Views/Curta/Home"
import ClientList from "./Views/Curta/ClientList";
import ClientInfo from "./Views/Curta/ClientInfo";
import CustomerList from "./Views/Curta/CustomerList";
import CustomerInfo from "./Views/Curta/CustomerInfo";
import MerchantList from "./Views/Curta/MerchantList";
import MerchantInfo from "./Views/Curta/MerchantInfo";
import RowList from "./Views/Curta/RowList"
import RowInfo from "./Views/Curta/RowInfo"
import InvoiceList from "./Views/Curta/InvoiceList"
import InvoiceInfo from "./Views/Curta/InvoiceInfo"
import TransactionList from "./Views/Curta/TransactionList";
import TransactionInfo from "./Views/Curta/TransactionInfo";

import JirugiHome from "./Views/Jirugi/Home"
import OccasionList from "./Views/Jirugi/OccasionList"
import OccasionInfo from "./Views/Jirugi/OccasionInfo"

import MartialIdHome from "./Views/Martial-id/Home.vue"


export default createRouter({
    history: createWebHistory(),
    routes: [
        {path: "/login", name: "login", component: Login},
        {path: "/colors", name: "colors", component: Colors},
        {
            path: "", component: () => import("./Layouts/Main"), children: [
                {path: "", name: "home", component: Home},
                {path: "public", name: "public", component: Public},

                {path: "id", name: "id", component: IdHome},
                {path: "id/users", name: "user-list", component: UserList},
                {path: "id/users/:id", name: "user-info", component: UserInfo},

                {path: "curta", name: "curta", component: CurtaHome},
                {path: "curta/clients", name:"client-list", component: ClientList},
                {path: "curta/clients/:id", name: "client-info", component: ClientInfo},
                {path: "curta/customers", name:"customer-list", component: CustomerList},
                {path: "curta/customers/:id", name: "customer-info", component: CustomerInfo},
                {path: "curta/merchants", name:"merchant-list", component: MerchantList},
                {path: "curta/merchants/:id", name: "merchant-info", component: MerchantInfo},
                {path: "curta/rows", name: "row-list", component: RowList},
                {path: "curta/rows/:id", name: "row-info", component: RowInfo},
                {path: "curta/invoices", name: "invoice-list", component: InvoiceList},
                {path: "curta/invoices/:id", name: "invoice-info", component: InvoiceInfo},
                {path: "curta/transactions", name: "transaction-list", component: TransactionList},
                {path: "curta/transactions/:id", name: "transaction-info", component: TransactionInfo},

                {path: "jirugi", name: "jirugi", component: JirugiHome},
                {path: "jirugi/occasions", name: "occasion-list", component: OccasionList},
                {path: "jirugi/occasion/:id", name: "occasion-info", component: OccasionInfo},

                {path: "martial-id", name: "martial-id", component: MartialIdHome},
            ]
        },
    ],
})

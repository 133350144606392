<template>

  <h1>
    {{ t("occasion", 1) }}
  </h1>

  <div class="row">
    <div class="col-md-6">
      <div class="card mt-3">
        <div class="card-body">

          <h5>{{ t("general") }}</h5>

          <div class="mb-3">
            <label class="form-label">{{ t("id") }}</label>
            <input class="form-control" type="text" name="id" v-model="data.occasion.id" disabled="disabled">
          </div>

          <div class="mb-3">
            <label class="form-label">{{ t("name") }}</label>
            <input class="form-control" type="text" name="name" v-model="data.occasion.name">
          </div>

          <div class="mb-3">
            <label class="form-label">{{ t("host") }}</label>
            <input class="form-control" type="text" name="host" v-model="data.occasion.host">
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import {onMounted, reactive} from "vue";
import {useRoute} from "vue-router";
import {ajax} from "slab-vue-utils";

const {t} = useI18n()
const route = useRoute()
const data = reactive({
  occasion: {},
})

onMounted(() => {
  ajax.authGet("/api/jirugi-base/occasions/" + route.params.id).then(resp => {
    data.occasion = resp.data.data
  })
})
</script>

<template>

  <h1>
    {{ t("user", 1) }}
  </h1>

  <div class="row">

    <div class="col-md-6">
      <div class="card mt-3">
        <div class="card-body">
          <form @submit.prevent="submit">

            <h5>{{ t("general") }}</h5>

            <div class="mb-3">
              <label class="form-label">{{ t("id") }}</label>
              <input class="form-control" type="text" name="id" v-model="data.user.id" disabled="disabled">
            </div>

            <div class="mb-3">
              <label class="form-label">{{ t("email") }}</label>
              <input class="form-control" type="text" name="email" v-model="data.user.email">
            </div>

            <div class="mb-3">
              <label class="form-label">{{ t("password") }}</label>
              <input class="form-control" type="password" name="password" v-model="data.user.password">
            </div>

            <div class="btn-group mb-3">
              <button class="btn btn-success" :class="{'is-loading': data.loadingSave}">{{ t("save") }}</button>
            </div>

          </form>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card mt-3">
        <div class="card-body">

          <h5>{{ t("permission", 2) }}</h5>
          <div v-if="data.redrawPermissions">
            <PermissionEdit v-for="permission in data.permissions" :permission="permission" :userid="route.params.id"
                            :remove="removePermission"/>
          </div>
          <button class="btn btn-primary btn-sm" @click="addPermission">{{ t("add") }}</button>
        </div>
      </div>
    </div>

  </div>


  <div v-if="!data.advancedLoaded" class="mt-5">
    <button class="btn btn-primary" @click="loadAdvanced">{{ t("load_more") }}</button>
  </div>

  <div v-else>

    <h2 class="mt-5">{{ t("advanced") }}</h2>

    <div class="row">
      <div class="col-md-6">

        <div class="card mt-3">
          <div class="card-body">
            <h5>{{ t('token', 2) }}</h5>

            <ul>
              <li v-for="token in data.tokens">{{ token.token }}</li>
            </ul>

          </div>
        </div>

      </div>
      <div class="col-md-6">

        <div class="card mt-3">
          <div class="card-body">
            <h5>{{ t('refresh_token', 2) }}</h5>

            TODO:

          </div>
        </div>

      </div>
    </div>

    <pre>
// email_verifications
// recovery tokens
</pre>

  </div>


</template>

<script setup>
import {onMounted, reactive} from "vue";
import {useRoute} from "vue-router";
import {ajax} from "slab-vue-utils";
import PermissionEdit from "@/Componenets/PermissionEdit.vue";
import {useI18n} from "vue-i18n";

const {t} = useI18n()
const route = useRoute()
const data = reactive({
  user: {},
  permissions: [],
  redrawPermissions: true,
  loadingSave: false,

  advancedLoaded: false,
  tokens: [],
})

function addPermission() {
  data.permissions.push({
    name: "",
    userId: route.params.id,
  })
}

function removePermission(permission) {
  let index = data.permissions.indexOf(permission);
  if (index !== -1) {
    data.permissions.splice(index, 1);
  }
  data.redrawPermissions = false
  setTimeout(() => {
    data.redrawPermissions = true
  }, 1)
}

function submit() {
  data.loadingSave = true
  ajax.authPut("/api/id/v1/users/" + route.params.id, data.user).then(resp => {
    data.user = resp.data.data
    data.loadingSave = false
  })
}

function loadAdvanced() {
  data.advancedLoaded = true
  console.log("loading advanced ")
  ajax.authGet("/api/id/v1/users/" + route.params.id + "/tokens").then(resp => {
    data.tokens = resp.data.data
  })
}

onMounted(() => {
  ajax.authGet("/api/id/v1/users/" + route.params.id).then(resp => {
    data.user = resp.data.data
  })
  ajax.authGet("/api/id/v1/users/" + route.params.id + "/permissions").then(resp => {
    data.permissions = resp.data.data
  })
})
</script>
